@import "@angular/material/theming";

@mixin dialog-component-theme($config-or-theme) {
  $config: mat-get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  .mat-dialog-container {
    padding-top: 0px;

    .mat-dialog-title {
      color: mat-color($foreground, text);
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.15px;
    }

    .mat-dialog-actions {
      padding: 0;
      margin-bottom: 0;
      min-height: 100%;
    }
  }

  .search-wizard {
    &.search-wizard-alert, &.search-wizard-mitigation {
      width: auto;
    }
    width: 620px;

    .mat-dialog-content {
      max-height: 75vh;
    }

    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mat-icon-button {
        width: initial;
        height: initial;
      }

      h2 {
        margin: 0;
      }
    }

    .section-header {
      font-weight: 700;
      font-size: 16px;
      margin: 16px 0 0 0;
    }
    
    .wizard-body {
      margin: 20px 0;
    }

    .mat-form-field {
      width: 180px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0px;
      }

      &.lg-field {
        width: 100%;
      }

      &.sm-field {
        width: 80px;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 5px;
    }

    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px;
    }
    .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
    }

    .mat-form-field-invalid {
      padding-bottom: 5px;

      .mat-form-field-subscript-wrapper {
        margin-top: 1.5rem;
      }
    }
  }
}
