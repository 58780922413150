@font-face {
    font-family: "ATT Aleck Sans";
    src: url("./ATTAleckSans/ATTAleckSans_Lt.ttf");
    font-weight: 300;
    font-style: light;
}

@font-face {
    font-family: "ATT Aleck Sans";
    src: url("./ATTAleckSans/ATTAleckSans_Rg.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "ATT Aleck Sans";
    src: url("./ATTAleckSans/ATTAleckSans_Md.ttf");
    font-weight: 500;
    font-style: medium;
}


@font-face {
    font-family: "ATT Aleck Sans";
    src: url("./ATTAleckSans/ATTAleckSans_Bd.ttf");
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: "ATT Aleck Sans";
    src: url("./ATTAleckSans/ATTAleckSans_It.ttf");
    font-weight: normal;
    font-style: italic;
}
