@use "sass:map";
@use "@angular/material" as mat;

@mixin _color($palette) {
  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($palette);
  }

  .mat-radio-inner-circle,
  .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  &.mat-radio-checked .mat-radio-persistent-ripple,
  &:active .mat-radio-persistent-ripple {
    background-color: mat.get-color-from-palette($palette);
  }
}

@mixin radio-color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, accent);
  $accent: map.get($config, primary);
  $warn: map.get($config, warn);
  $info: map.get($config, info);
  $success: map.get($config, success);
  $error: map.get($config, error);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $color: mat.get-color-config($config);

  .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .mat-radio-button {
    &.mat-primary {
      @include _color($primary);
    }

    &.mat-accent {
      @include _color($accent);
    }

    &.mat-warn {
      @include _color($warn);
    }
    &.mat-info {
      @include _color($info);
    }

    &.mat-success {
      @include _color($success);
    }

    &.mat-error {
      @include _color($error);
    }
  }
}
