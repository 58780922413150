@use "@angular/material" as mat;
@use "./styles/themes/ddos_theme" as ddos-theme;
@use "./styles/typography/config" as typography;
@use "./styles/themes/ddos-components-theme" as ddos-components;
@use "./styles/scss/ddos-class-generator";

@include mat.core(typography.$ddos-ui-typography);
@include mat.core-theme(ddos-theme.$ddos-ui-light-theme);
@include mat.all-component-themes(ddos-theme.$ddos-ui-light-theme);
@include ddos-components.component-theme(ddos-theme.$ddos-ui-light-theme);
@include ddos-class-generator.generate-classes();

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: ddos-theme.$ddos-ui-font-family;
  background-color: ddos-theme.$ddos-ui-gray-body;
  height: 100vh;
  overflow: hidden;
}

.snackbar {
  max-width: 90vw !important;
  min-width: 60vw !important;
  padding: 0 !important;
  box-shadow: none !important;
  background: none !important;
}
