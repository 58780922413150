@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $typography: mat.get-typography-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $info: map.get($config, info);
  $success: map.get($config, success);
  $error: map.get($config, error);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .no-alert {
    padding: 12px;
    min-height: 56px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: mat.get-color-from-palette($accent, 500);
    background-color: mat.get-color-from-palette($accent, 100);
    border: 1px solid mat.get-color-from-palette($accent, 700);
    h1 {
      font-size: 14px;
      margin: 0 0 0 5px;
    }
  }

  .ddos-alert {
    padding: 12px !important;
    margin-bottom: 12px;

    .mat-card-title {
      display: flex;
      align-items: center;
			color: inherit;
      font-weight: bold;

      .mat-icon {
        margin-right: 10px;
        font-size: 20px;
        align-self: center;
        display: block;
        height: 20px;
        width: 20px;
        font-weight: inherit;
      }

      & h3 {
        font-size: 16px;
        margin: 0;
        font-weight: inherit;
      }
    }

    .mat-card-content {
      display: flex;
      padding: 0;
      justify-content: space-between;

      .graph {
        margin-top: -27px;
        margin-left: 12px;
      }

      .details {
        margin: 0;
        font-size: 16px;
      }
    }
  }

	.ddos-alert-ongoing {
		@extend .ddos-mitigation;
		color: mat.get-color-from-palette($error, 900);
		border: 1px solid mat.get-color-from-palette($error, 500);
	}

	.ddos-alert-normal {
		@extend .ddos-mitigation;
		color: black;
		border: 1px solid black;
	}
}
