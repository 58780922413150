@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $typography: mat.get-typography-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $info: map.get($config, info);
  $success: map.get($config, success);
  $error: map.get($config, error);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-card {
    height: 100%;
    padding: 24px;

    .ddos-no-elevation {
      margin-top: 20px;
      border: 1px solid lightgray;
      border-radius: 5px;
      box-shadow: none;
    }
    .padding-0 {
      padding: 0 !important;
    }
    .margin-0 {
      //  margin: 0 !important;
      margin-right: 2.5px !important; 
      

    }
    .text-align-center {
      text-align: center;
    }

    .detail-title {
      justify-content: left !important;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 15px;

      .fw-400 {
        font-weight: 400;
      }

      .action-icons {
        justify-content: end;
        flex: 1;
        color: rgba(0, 0, 0, 0.54);

        .mat-icon {
          font-size: 18px;
        }
      }
    }

    .mat-card-title-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mat-card-title {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 0px;
        font-weight: 400;
        letter-spacing: 0.15px;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: 500;

        &:not[class="mat-button-wrapper"] {
          .mat-icon {
            margin-left: 10px;
          }
        }
      }
    }

    .normal {
      .mat-card-title {
        font-size: 14px;
      }
    }

    table.mat-table {
      width: 100%;
    }

    th.mat-header-cell,
    // td.mat-cell,
    td.mat-footer-cell {
      text-transform: capitalize;
    }

    th.mat-header-cell {
      color: #000;
    }

    .actions {
      .mat-icon:last-child {
        margin: 0 20px;
      }
    }
  }

  .mat-card > .mat-divider-horizontal.mat-divider-inset {
    margin: 10px 0 20px 0 !important;
  }
}
