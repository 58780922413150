@use "sass:map";
@use "@angular/material" as mat;
@use "../scss/palettes.scss" as palettes;

$ddos-ui-gray-body: #eaeaea;
$ddos-ui-white-body: #fff;
$ddos-ui-black-body: #000;
$ddos-ui-font-family: "ATT Aleck Sans";
$ddos-ui-light-primary: mat.define-palette(palettes.$primary);
$ddos-ui-light-accent: mat.define-palette(palettes.$accent);
$ddos-ui-light-success: mat.define-palette(palettes.$success);
$ddos-ui-light-info: mat.define-palette(palettes.$info);
$ddos-ui-light-warn: mat.define-palette(palettes.$warn);
$ddos-ui-light-error: mat.define-palette(palettes.$error);

$ddos-ui-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $ddos-ui-light-primary,
      accent: $ddos-ui-light-accent,
      warn: $ddos-ui-light-warn,
    ),
  )
);

$ddos-ui-light-theme: map.deep-merge(
  $ddos-ui-light-theme,
  (
    success: $ddos-ui-light-success,
    info: $ddos-ui-light-info,
    error: $ddos-ui-light-error,
    color: (
      info: $ddos-ui-light-info,
      success: $ddos-ui-light-success,
      error: $ddos-ui-light-error,
    ),
  )
);

$ddos-ui-light-variants: (
  success: $ddos-ui-light-success,
  info: $ddos-ui-light-info,
);
