/* For use in src/lib/core/theming/_palette.scss */
$primary: (
  50: #e0ebf6,
  100: #b3cdea,
  200: #80abdc,
  300: #4d89cd,
  400: #2670c3,
  500: #0057b8,
  600: #004fb1,
  700: #0046a8,
  800: #003ca0,
  900: #002c91,
  A100: #bdcbff,
  A200: #8aa3ff,
  A400: #577cff,
  A700: #3d68ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$accent: (
  50: #e6f0e5,
  100: #eff7ee,
  200: #96bf92,
  300: #6ca566,
  400: #4d9145,
  500: #2d7e24,
  600: #287620,
  700: #226b1b,
  800: #1c6116,
  900: #114e0d,
  A100: #8aff85,
  A200: #58ff52,
  A400: #27ff1f,
  A700: #0fff06,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$info: (
  50: #e2eef7,
  100: #b6d4ec,
  200: #7acbe9,
  300: #549ad2,
  400: #2f84c8,
  500: #0a6ebe,
  600: #0966b8,
  700: #075baf,
  800: #0551a7,
  900: #033f99,
  A100: #c4d8ff,
  A200: #91b6ff,
  A400: #5e94ff,
  A700: #4583ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$warn: (
  50: #f8eae2,
  100: #efcab7,
  200: #e4a787,
  300: #d98456,
  400: #d06932,
  500: #c84f0e,
  600: #c2480c,
  700: #bb3f0a,
  800: #b43608,
  900: #a72604,
  A100: #ffd8d1,
  A200: #ffad9e,
  A400: #ff826b,
  A700: #ff6c52,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$error: (
  50: #f8e0e6,
  100: #eeb3c2,
  200: #e38099,
  300: #d84d70,
  400: #cf2651,
  500: #c70032,
  600: #c1002d,
  700: #ba0026,
  800: #b3001f,
  900: #55000a,
  A100: #ffd0d3,
  A200: #ff9da3,
  A400: #ff6a73,
  A700: #ff515b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$success: (
  50: #e6f0e5,
  100: #c0d8bd,
  200: #96bf92,
  300: #6ca566,
  400: #4d9145,
  500: #2d7e24,
  600: #287620,
  700: #226b1b,
  800: #1c6116,
  900: #114e0d,
  A100: #8aff85,
  A200: #58ff52,
  A400: #27ff1f,
  A700: #0fff06,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
