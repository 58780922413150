@use "sass:map";
@use "@angular/material" as mat;

@mixin checkbox-theme($config-or-theme) {
  $config: mat-get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map-get($config, accent);
  $accent: map-get($config, primary);
  $warn: map-get($config, warn);
  $info: map.get($config, info);
  $success: map.get($config, success);
  $error: map.get($config, error);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-checkbox-indeterminate,
  .mat-checkbox-checked {
    &.mat-primary .mat-checkbox-background {
      background-color: mat.get-color-from-palette($primary);
    }

    &.mat-accent .mat-checkbox-background {
      background-color: mat.get-color-from-palette($accent);
    }

    &.mat-warn .mat-checkbox-background {
      background-color: mat.get-color-from-palette($warn);
    }

    &.mat-info .mat-checkbox-background {
      background-color: mat.get-color-from-palette($info);
    }

    &.mat-success .mat-checkbox-background {
      background-color: mat.get-color-from-palette($success);
    }

    &.mat-error .mat-checkbox-background {
      background-color: mat.get-color-from-palette($error);
    }
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled),
  .mat-checkbox:active:not(.mat-checkbox-disabled) {
    &.mat-primary .mat-ripple-element {
      background: mat.get-color-from-palette($primary);
    }

    &.mat-accent .mat-ripple-element {
      background: mat.get-color-from-palette($accent);
    }

    &.mat-warn .mat-ripple-element {
      background: mat.get-color-from-palette($warn);
    }

    &.mat-info .mat-checkbox-background {
      background-color: mat.get-color-from-palette($info);
    }

    &.mat-success .mat-checkbox-background {
      background-color: mat.get-color-from-palette($success);
    }

    &.mat-error .mat-checkbox-background {
      background-color: mat.get-color-from-palette($error);
    }
  }
}
